<template>
  <div class="page">
    <el-tabs v-model="searchObj.state" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="-1"></el-tab-pane>
      <el-tab-pane label="未审核" name="0"></el-tab-pane>
      <el-tab-pane label="已审核" name="1"></el-tab-pane>
      <el-tab-pane label="已拒绝" name="2"></el-tab-pane>
      <el-tab-pane label="已支付" name="3"></el-tab-pane>
    </el-tabs>
    <el-table :data="refundList" @selection-change="handleSelectionChange">
      <el-table-column
          v-if="searchObj.state != '-1' && searchObj.state != '3'"
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column label="退款原因" prop="support_reason"></el-table-column>
      <el-table-column label="金额">
        <template slot-scope="scope">
          <span>{{scope.row.amount}} {{scope.row.currency}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state == 0">未审核</span>
          <span v-if="scope.row.state == 1">已审核</span>
          <span v-if="scope.row.state == 2">已拒绝</span>
          <span v-if="scope.row.state == 3">已支付</span>
        </template>
      </el-table-column>
      <el-table-column label="审核员" prop="audit_admin_text"></el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          <span>{{scope.row.pay_at | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="searchObj.state != '3'">
        <template slot="header">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <span>操作</span>
            <el-link :underline="false" type="primary" v-if="searchObj.state == '0' || searchObj.state == '2'" @click="changeStateMultiple(1)">批量审核</el-link>
            <el-link :underline="false" type="primary" v-if="searchObj.state == '1'" @click="changeStateMultiple(2)">批量支付</el-link>
          </div>
        </template>
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" style="margin-right: 10px" v-if="scope.row.state == 0 || scope.row.state == 2" @click="dialogVisible = true;dialogState = scope.row.state;changeForm.ids = [scope.row.id];changeForm.state = 1">审核</el-link>
          <el-link :underline="false" type="primary" style="margin-right: 10px" v-if="scope.row.state == 1" @click="dialogVisible = true;dialogState = scope.row.state;changeForm.ids = [scope.row.id];changeForm.state = 3">支付</el-link>
          <el-link :underline="false" type="primary" style="margin-right: 10px" @click="toOrderDetail(scope.row.order_id)">查看订单</el-link>
          <el-link :underline="false" type="primary" style="margin-right: 10px" @click="toAfterSaleDetail(scope.row.support_id)">查看售后单</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>

    <el-dialog
        title="更换退款状态"
        :visible.sync="dialogVisible">
      <el-form>
        <el-form-item label="状态">
          <el-select v-model="changeForm.state">
            <el-option :value="1" label="审核" v-if="dialogState == 0 || dialogState == 2"></el-option>
            <el-option :value="2" label="拒绝" v-if="dialogState == 0 || dialogState == 2"></el-option>
            <el-option :value="3" label="支付" v-if="dialogState == 1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <tinymce v-model="changeForm.note"></tinymce>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeState">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {apiGetRefundList,apiChangeRefundState} from "@/request/api";

export default {
  name: "refundsList",
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  data() {
    return {
      pageCount:0,
      searchObj:{
        page:1,
        'per-page':10,
        state:'-1'
      },
      refundList:[],
      dialogVisible:false,
      dialogState:0,
      changeForm:{
        ids:[],
        state:0,
        note:''
      },
      multipleSelection:[]
    }
  },
  created() {
    this.getRefundList()
  },
  methods:{
    handleClick(){
      this.searchObj.page = 1
      this.getRefundList()
    },
    getRefundList(){
      apiGetRefundList(this.searchObj).then(res => {
        if(res.code == 200) {
          this.refundList = res.data.list
          this.pageCount = res.data.count
        }else{
          this.$message.error(res.message)
        }
      })
    },
    pageChangeHandle(){
      this.getRefundList()
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    changeStateMultiple(type){
      if (this.multipleSelection.length == 0){
        this.$message.error('请选择记录再批量处理')
        return
      }
      if (type == 1) {
        this.dialogState = 0
        this.changeForm.state = 1
      }
      if (type == 2) {
        this.dialogState = 1
        this.changeForm.state = 3
      }
      this.dialogVisible = true
      this.changeForm.ids = this.multipleSelection.map(item => {return item.id})
    },
    changeState(){
      apiChangeRefundState(this.changeForm).then(res => {
        if(res.code == 200){
          this.getRefundList()
          this.dialogVisible = false
        }
      })
    },
    toAfterSaleDetail(id){
      this.$router.push({path:'/afterSale/afterSaleDetail',query:{id}})
    },
    toOrderDetail(id){
      this.$router.push({path:'/order/orderDetail',query:{id}})
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
.pageView {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
 }
</style>
